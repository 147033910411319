$default-color: #00819e;
$default-color-dark: #00667d;
$default-color-darker: #004c5d;

.theme-aqua {
    //Header
    .main-nav__item {
        &::before {
            background: $default-color;
        }
    }

    .main-nav__link {
        &.main-nav__link--active {
            &::after {
                background-color: $default-color;
            }
        }
    }

    .header-search__input {
        border-top: 2px solid $default-color;
        @media (min-width: 37.5em) {
            border: none;
            border-left: 2px solid $default-color;
        }
    }

    .search-icon,
    .menu-icon,
    .close-icon {
        fill: $default-color;
    }
    //Mega menu
    .mega-menu__wrapper {
        border-top: 2px solid $default-color;
        border-bottom: 2px solid $default-color;
    }

    //Quick Links
    .quicklinks__block .quicklinks__heading {
        background: $default-color;
    }

    //Feature box
    .small-box .feature-box__heading {
        background: $default-color;
    }

    .feature-box__content a {
        color: $default-color;
    }

    //Content
    .content {
        th {
            background: $default-color;
        }
    }

    //Accordions
    .accordion__heading {
        .active & {
            background: $default-color;
        }
        background: $default-color;
    }

    //Tabs
    .tab__button {
        background: $default-color;
        &.active {
            background: #e6e6e6;
        }
    }

    //Gallery
    .gallery__nav {
        .slick-prev {
            border-color: transparent $default-color transparent transparent;
        }

        .slick-next {
            border-color: transparent transparent transparent $default-color;
        }
    }



    //sidebar
    .sidebar__menu-link.active, 
    .sidebar__menu-link.current {
        background: $default-color;
        color: #ffffff;
    }

    .menu__level-2 .sidebar__menu-link.active, 
    .menu__level-2 .sidebar__menu-link.current {
        background: $default-color-dark;
    }

    .menu__level-3 .sidebar__menu-link.active, 
    .menu__level-3 .sidebar__menu-link.current {
        background: $default-color-darker;
    }

    .sidebar__menu-link.current.has-children::after {
        border-right: 3px solid #ffffff;
        border-bottom: 3px solid #ffffff;
    }

    //RHS
    .rhs__panel > .rhs__panel-heading {
        background: $default-color;
        color: #ffffff;
    }

    //Pagination
    .pagination__item--current > span {
        background: $default-color;
        color: #ffffff;
    }

    .pagination__previous .pagination__link::before {
        border-left: 4px solid $default-color;
        border-bottom: 4px solid $default-color;
    }

    .pagination__next .pagination__link::after {
        border-right: 4px solid $default-color;
        border-bottom: 4px solid $default-color;
    }

    //Back to top
    #btn-btt {
        background-color: $default-color;
        border: 2px solid $default-color;
        &:hover {
            border-color: $default-color;
            background-color: #FFF;
        }
    }

    .btt a:hover::before, .btt a:focus::before {
        border-color: $default-color;
    }

    //Footer 
    .footer-link {
        &:hover {
            color: $default-color;
        }
    }
}
